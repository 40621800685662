
import EmailPhoneContact from './EmailPhoneContact'

const Faq6 = () => (
 <span>
   My Giving Gateway provides receipts for the past two calendar years. Please note that these receipts are only for gifts where you are considered the legal donor.
If you need a receipt for an additional gift,<EmailPhoneContact/>
 </span>
)

export default Faq6
