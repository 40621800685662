import css from '../sass/adminitem.module.scss'
import ClosedItemDisplayRow from './ClosedItemDisplayRow'

export default function ClosedItemDisplay( {selectedAuction} ) {

  // maybe provide a link for if no auction is loaded to reroute to load auctions - 404 something
  if (!selectedAuction) return (<></>)
  
  return (
    <>
      <div className={css['admin-items-container']}>
        <table className={css['admin-item-table']}>
          <thead>
            <tr className={css['admin-item-row']}>
              <th></th>
              <th>Item Title</th>
              <th>Fair Market Value</th>
              <th>Starting Bid</th>
              <th>Min. Raise</th>
              <th>Item #</th>
              <th>Donated By</th>
              <th># Bids</th>
              <th>Current Bid</th>
              <th>User</th>
            </tr>
          </thead>
          <tbody className={css['admin-item-body']}>
            {selectedAuction?.Items && selectedAuction.Items.map((item) => {
              return (
                <ClosedItemDisplayRow key={item.ItemId} auction={selectedAuction} item={item} />
                )
              })}
          </tbody>
        </table>

        {selectedAuction.Items.length === 0 && <p>No Items, add some!</p>}
      </div>
    </>
  )
}