import { useState, useEffect } from 'react'
import { useQuery } from "@tanstack/react-query"

import { getUpcomingAuctions } from '../api'

import css from './sass/adminpage.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import ItemDisplay from './ItemDisplay'

export default function AdminItemPage() {

  const [upcomingAuctionList, setUpcomingAuctionList] = useState()
  const [selectedAuction, setSelectedAuction] = useState({})

  // load auction data by code, so it will work for deep-linking
  const { data: auctions, error, isLoading } = useQuery({
    queryKey: ["upcomingAuctionList"],
    queryFn: async () => await getUpcomingAuctions(),
    staleTime: 0
  })

  useEffect(() => {
    if (!auctions) return
    const upcomingAuctions = auctions.sort((a, b) => (a.StartDate < b.StartDate) ? -1 : (a.StartDate > b.StartDate) ? 1 : 0).map((auction) => { return {...auction, 'displayData': false}})
    setUpcomingAuctionList(upcomingAuctions)
  }, [auctions])

  const hasAuction = 'AuctionId' in selectedAuction

  return (
    <div>
      <div className={css['item-page-header']}>
        <h2>{hasAuction ? `Viewing ${selectedAuction.Title}` : 'Select an auction to view or modify items'}</h2>
        {hasAuction && <button className={`${themeCss['button']} ${themeCss['FilledBlue']}`} onClick={()=> setSelectedAuction({})}>Return to Auction Selection</button>}
      </div>
      {upcomingAuctionList && !hasAuction && upcomingAuctionList.map((auction) => {
        return (
          <div key={auction.AuctionId} className={css['row-container']}>
            <div className={css['admin-auction-row']} onClick={() => {
              setSelectedAuction(auction)
              }}>
              <p>{auction.Title} </p>
            </div>
          </div>
        )
      })}
      {hasAuction && <ItemDisplay selectedAuction={selectedAuction} />}
    </div>
  )
}