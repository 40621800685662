import { Link } from 'react-router-dom'

import { formatPhone } from 'admin/adminutil'
import { formatName } from '../auctionsadminutil'

import css from './sass/liveauction.module.scss'

export default function LiveAuctionRegistrationRow( {registration, auctionCode} ) {
  // console.log(registration)

  // make sure to update copyAttendeesToClipboard in LiveAttendeeView if these columns change
  return (
    <tr className={css['live-auction-table-data-row']}>
      <td>{formatName(registration)}</td>
      <td>{registration.IsCheckedIn ? 'Yes' : "No"}</td>
      <td>{registration.User.ContactEmail}</td>
      <td>{registration.User.CrmId}</td>
      <td>{formatPhone(registration.User.ContactPhone)}</td>
    </tr>
  )
}