import { useEffect, useState} from 'react'
import { useSelector } from "react-redux"
import { useQuery, useQueryClient } from "@tanstack/react-query"

import store from 'redux/store'
import { getLoggedInUser } from 'redux/selectors';
import { history } from 'services/history.js'
import { showLoading } from 'services/util'

import { subscribe, getAuctionByCode, getAuctionUserApi} from '../api'
import { onUpdateCurrentBidSubscription, onUpdateAuctionSubscription } from '../graphql/Subscriptions'
import { setBidSubscriptionResponse } from '../redux/actions'
import { getAuctionUser, getLiveAuctionCode } from '../redux/selectors'

import alertIcon from '../../../images/Alerticonmodal.svg'

import css from './sass/notification.module.scss'
import themeCss from '../components/sass/theme.module.scss'

export default function OutBidNotification() {

  const [subResponse, setSubResponse] = useState()
  const [outBidItems, setOutBidItems] = useState([])
  const [myItems, setMyItems] = useState([])
  const [auctionId, setAuctionId] = useState()

  const queryClient = useQueryClient()

  // system assumes only one live auction at a time, which I think is a fair assumption to not add tons of code to handle multiple live auctions
  const liveAuctionCode = useSelector(getLiveAuctionCode)

  const auctionUser = useSelector(getAuctionUser)
  const loggedInUser = useSelector(getLoggedInUser)

  // reload auction user if the page is refreshed (backup plan for check in not showing up)
  useEffect(() => {
    if (!loggedInUser)
      return

    async function refreshAuctionUser() {
      showLoading(true)
      // updates auctionUser in redux/localStorage
      await getAuctionUserApi()
      showLoading(false)
    }

    refreshAuctionUser()
  }, [loggedInUser])

const currentUserId = auctionUser?.UserId

  // always get fresh auction date from code
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["auctionDetail", liveAuctionCode],
    queryFn: async () => {
      if (liveAuctionCode)
        return await getAuctionByCode(liveAuctionCode)
      else
        return null
    },
    staleTime: 0
  })

  useEffect(() => {
    if (auctionDetail) setAuctionId(auctionDetail.AuctionId)
  }, [auctionDetail])

  useEffect(() => {
    if (auctionDetail && currentUserId) {
      const filteredItems = auctionDetail?.Items?.filter(item => item.CurrentHighBidder === currentUserId).map(item => item.ItemId)
      setMyItems(filteredItems)
    }
  }, [auctionDetail, currentUserId])

  // this is executed any time a bid is successfully updated
  // use the result to update the current bid for that item on the client
  useEffect(() => {
    if (!auctionId)
      return

    const subCreateFiltered = subscribe({query: onUpdateCurrentBidSubscription, variables: {auctionId}}, {
      next: (result) => {
        console.log('Bid subscription result', result)
        setSubResponse(result.value.data.onUpdateCurrentBid)
        store.dispatch(setBidSubscriptionResponse({bidSubscriptionResponse: result.value.data.onUpdateCurrentBid}));
      },
      error: (error) => console.error("Bid subscription error!", error)
    })

    return () => {
      console.log('unsubscribing...')
      subCreateFiltered.unsubscribe()
    }
  }, [auctionId, setSubResponse])

  // used to end bidding early, auction can be monitored for other things
  useEffect(() => {
    if (!auctionId)
      return

    const subCreateFiltered = subscribe({query: onUpdateAuctionSubscription, variables: {auctionId}}, {
      next: (result) => {
        console.log('Auction subscription result', result)
        queryClient.invalidateQueries(["auctionDetail"])
      },
      error: (error) => console.error("Auction subscription error!", error)
    })

    return () => {
      console.log('unsubscribing...')
      subCreateFiltered.unsubscribe()
    }
  }, [auctionId, queryClient])

  useEffect(() => {
    if (auctionDetail && subResponse) {

      const newArray = [...outBidItems]

      const oldItem = auctionDetail.Items.find((item) => item.ItemId === subResponse.ItemId)
      const newItem = {...oldItem, ...subResponse}

      const isInNotifications = outBidItems.findIndex(outBidItem => outBidItem.ItemId === subResponse.ItemId)

      //if it is in their notifications
      if (isInNotifications > -1) {
        //update the item - in case another bidder bid since the notification popped up
        newArray[isInNotifications] = newItem
        //if they are the current high bidder now, remove the notification
        if (subResponse.CurrentHighBidder === currentUserId) newArray.splice(isInNotifications, 1)
      }
      //if the item just updated is inside my items
      if (myItems.indexOf(subResponse.ItemId) > -1) {
        //and the current user is not bidding over themselves
        if (subResponse.CurrentHighBidder !== currentUserId) {
          //push that item into the outbid items
          newArray.push(newItem)
        }
      }
      setOutBidItems(newArray)
      setSubResponse(false)
    }
  }, [subResponse, setSubResponse, auctionDetail, currentUserId, myItems, outBidItems])


  const removeNotification = (index) => {
    const updatedArray = [...outBidItems]
    updatedArray.splice(index, 1)
    setOutBidItems(updatedArray)
  }

  console.log(window.location.href)

  // check if user is on the page for the item they were outbid on to update the url when they click on place new bid
  const reRouteParameters = (outbidItem) => {
    const currentUrl = window.location.href
    const itemPageEnding = `&itemId=${outbidItem.ItemCode}`
    
    //if they are currently on the item page for the item they were outbid on, return the same itemId url query
    if (currentUrl.endsWith(itemPageEnding)) {
      return { 
        pathname: `/auction/item`,
        search: `?auctionId=${auctionDetail.AuctionCode}&itemId=${outbidItem.ItemCode}`
      }
    } else {
      //if they are not, reroute to the auction
      return {
        pathname: '/auctionhome',
        search: `?auctionId=${auctionDetail.AuctionCode}`
      }
      
    }


  }

  if (!currentUserId) return <></>

  return (
    <>      
      <div className={css['notification-container']}>
        {outBidItems && outBidItems.map((outbidItem, index) => {
          console.log(outbidItem)
           return (
            <div key={index} className={css['outbid-box']}>
              <div className={css['outbid-box-line']}></div>
              <div className={css['outbid-box-top-row']}>
                <img src={alertIcon} className={css['outbid-box-icon']} alt={'Bid Successful'} />
                <h4 className={css['outbid-box-header']}>You've been outbid!</h4>
                <button className={css['outbid-box-close']} onClick={() => removeNotification(index)}>&times;</button>
              </div>
            <p className={css['outbid-box-text']}>
            You have been outbid on {outbidItem.Title}! Want to bid again?
            </p>
            <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${css['outbid-box-button']}`}
              onClick={() => {
                history.push({
                  // pathname: '/auctionhome',
                  // search: `?auctionId=${auctionDetail.AuctionCode}${onOutbidItemPage(outbidItem)}`,
                  ...reRouteParameters(outbidItem),
                  outbidNotification: {
                    outbidItem,
                    bidAmount: outbidItem.CurrentBid + parseInt(outbidItem.MinRaise),
                    showBidModal: true,
                  }
                })
              }}>
              PLACE NEW BID
              {/* can do this if we really want to, but it is going to need a useEffect to update outbid Items
              PLACE ${outbidItem.CurrentBid + outbidItem.MinRaise} BID */}
            </button>          
          </div>)
        })}
      </div>
    </>
  )
}