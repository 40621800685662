export const saveCalInvite = (auction) => {
  // const newEvent = { ...event, address: event?.event_url ? event?.event_url : `${event?.address?.line_1}, ${event?.address?.line_2}, ${event?.address?.city} ${event?.address?.state}, ${event?.address?.country} ${event?.address?.postal_code} ` }

  console.log(auction.EndTime)
  // Create the .ics URL

  //hardcoded for pacific time zone
  const timeZoneOffset = 8

  let url = [
    "BEGIN:VCALENDAR",
    "BEGIN:VTIMEZONE",
    "BEGIN:STANDARD", 
    "END:STANDARD",
    "END:VTIMEZONE",
    "BEGIN:VEVENT",
    // "DTEND;TZID=America/Los_Angeles:20240116T100000",
    // "DTSTART;TZID=America/Los_Angeles:20240116T090000",
    `DTEND:${formatDate(auction.EndTime, timeZoneOffset)}`,
    `DTSTART:${formatDate(auction.StartTime, timeZoneOffset)}`,
    // `DTEND;TZID=America/Los_Angeles:${formatDate(auction.EndTime, timeZoneOffset)}`,
    // `DTSTART;TZID=America/Los_Angeles:${formatDate(auction.StartTime, timeZoneOffset)}`,
    `LOCATION:${auction.AuctionLocation}`,
    `SUMMARY:${auction.Title}`,
    `UID:${uuidv4()}`,
    "END:VEVENT",
    "END:VCALENDAR"
  ].join("\n")

  let blob = new Blob([url], { type: 'text/calendar;charset=utf-8' })

  if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
    // Open/Save link in IE and Edge
    window.navigator.msSaveBlob(blob, `.ics`)
  } else {
    // Open/Save link in Modern Browsers
    // console.log(encodeURI("data:text/calendar;charset=utf8," + url))
    window.open(encodeURI("data:text/calendar;charset=utf8," + url))
  }

}

const formatDate = (auctionDate, timeZoneOffset) => {

  const auctionDateTime = new Date(auctionDate)
  const zonedDate = new Date(auctionDateTime.getTime() - (timeZoneOffset*60*60*1000))
  const zonedString = new Date(zonedDate).toISOString()

  let eventDate = ''
  let counter = 0

  while (eventDate.length < 15) {
    if (zonedString[counter] !== ':' && zonedString[counter] !== '-' && zonedString[counter] !== '.') {
      eventDate += zonedString[counter]
    }
    counter++
  }
  return eventDate
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, 
          v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}
