import { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import { useQuery, useQueryClient } from "@tanstack/react-query"

import { history } from 'services/history.js'
import { showLoading } from 'services/util'
import { getAuctionStatus } from '../util/dateString'
import store from 'redux/store'
import { setLiveAuctionCode } from '../redux/actions'

import { getAuctionByCode, updateUserRegistration, registerUserForAuction, getAuctionUserApi } from '../api'
import { urlQueryParser } from '../util/urlQueryParser'
import { isAttending } from '../util/userUtils'
import { updateLiveBid } from '../auctionUtils'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/auctions.module.scss'
import auctionCardCss from './sass/auctionscards.module.scss'

import AuctionCountdown from './AuctionCountdown'
import ConfirmBidModal from '../components/ConfirmBidModal'
import CustomMarkdown from 'sites/auctions/components/CustomMarkdown'

import TextMessageOff from './TextMessageOff'
import TextMessageOn from './TextMessageOn'

import { getAuctionUser, getBidSubscriptionResponse } from '../redux/selectors'
import BidSuccessNotification from '../components/BidSuccessNotification'
import AuctionStatus from '../components/AuctionStatus'
import AuctionEndedCard from './AuctionEndedCard'
import UnregisterButton from './UnregisterButton'

export default function CheckBackSoon({ props, auctionDetail, auctionUser, userAttending }) {

  const [date, setDate] = useState(new Date())
  const [auctionState, setAuctionState] = useState()
  const [textAlertNumber, setTextAlertNumber] = useState()
  const [isCheckedIn, setIsCheckedIn] = useState(false)
  // const [userAttending, setUserAttending] = useState()

  //bid and notifications
  const [showBidModal, setShowBidModal] = useState()
  const [bidItem, setBidItem] = useState()
  const [bidAmount, setBidAmount] = useState()
  const [bidResponse, setBidResponse] = useState()

  const [successItem, setSuccessItem] = useState()
  const successBannerTime = 7

  // for test / demo purposes
  const [forced, setForced] = useState('')
  const [iframeAction, setIframeAction]= useState('')

  // let auctionUser = useSelector(getAuctionUser)
  // const auctionUser = useSelector(getAuctionUser);
  const currentUserId = auctionUser?.UserId
  const userPhone = auctionUser.ContactPhone

  const bidSubscriptionResponse = useSelector(getBidSubscriptionResponse);

  //to refresh query on changes
  const queryClient = useQueryClient()

  //for simulation buttons
  const isAuctionAdmin = localStorage.getItem('isAuctionAdmin')

  // load auction data by code, so it will work for deep-linking

  // poll for auction user every 15 seconds if user is not checked in and Auction is upcoming within a day, or is running
  useEffect(() => {
    let timer

    function clearTimer() {
      if (timer) {
        console.log('clearing timer')
        clearInterval(timer)
      }
    }

    async function refreshCheckinStatus() {
      if (auctionDetail
          && !isCheckedIn
          && (auctionState === 'started'
              || (auctionState === 'before'
                  && (new Date(auctionDetail?.StartTime).getTime() - new Date().getTime()) > 1000*60*60*24))
        ) {

        console.log('refreshing checkin')

        timer = setInterval(async () => {
          console.log('checking auction user')
          await getAuctionUserApi()
        }, 1000*15)
      }
      else {
        console.log('not refreshing checkin')
        clearTimer()
      }
      return
    }

    refreshCheckinStatus()

    return clearTimer

  }, [auctionDetail, isCheckedIn, auctionState])

  useEffect(() => {
    if (props.location.outbidNotification) {
      setShowBidModal(props.location.outbidNotification.showBidModal)
      setBidItem(props.location.outbidNotification.outbidItem)
      setBidAmount(props.location.outbidNotification.bidAmount)
    }

  }, [props.location.outbidNotification])

  // redux liveAuctionCode is consumed by <OutBidNotification> set in the theme header (and could be by other global widgets)
  // I think it's best to only set auctionCode and force the widget to get a fresh version of the auction (since some things can change)
  // system assumes only one live auction at a time, which I think is a fair assumption to not add tons of code to handle multiple live auctions
  useEffect(() => {
    if (auctionState === 'started') {
      store.dispatch(setLiveAuctionCode(auctionDetail?.AuctionCode))
    }
    else {
      store.dispatch(setLiveAuctionCode())
    }
   }, [auctionState, auctionDetail?.AuctionCode])
  
  //is the user attending
  // useEffect(() => {
  //   const isUserAttending = isAttending(auctionDetail?.AuctionId, auctionUser)
  //   setUserAttending(isUserAttending)
  // }, [auctionDetail, auctionUser])

  //event listener for iframe to hard code values
  useEffect(() => {
    if (iframeAction) {
      // if (iframeAction === 'notAttending') setUserAttending(false)
      // if (iframeAction === 'attending') setUserAttending(true)
      if (iframeAction === 'checkIn') setIsCheckedIn(true)
      if (iframeAction === 'checkOut') setIsCheckedIn(false)
      if (iframeAction === 'auctionLive') setForced('live')
      if (iframeAction === 'auctionEnd') setForced('end')
    }
  }, [iframeAction])

  // const toggleAttending = () => {
  //   if (userAttending) setUserAttending(false)
  //   else setUserAttending(true)
  // }

  //checking registration for checked in status and text alert status
  useEffect(() => {
    if (!auctionDetail || !auctionUser) return

    const myRegistration = auctionUser.MyAuctions.filter(registration => {
      return registration.Auction.AuctionId === auctionDetail.AuctionId
    })[0]


    if (myRegistration) {
      setTextAlertNumber(myRegistration.TextAlertNumber)
      setIsCheckedIn(myRegistration.IsCheckedIn)
    }
    
  }, [auctionDetail, auctionUser])

  const updateStatus = (status) => {
    setAuctionState(status)
  }

  const updateBidModalAmount = (itemBidAmount) => {
    setBidAmount(itemBidAmount)
  }

  const submitBid = async (item, bidAmount) => {
    showLoading(true)
  
    let updatedItem = {}
  
    const bidFields = {
      itemId: item.ItemId,
      bidAmount,
    }
    try {
      const response = await updateLiveBid(bidFields)
      setBidResponse(response)

      if (response.error) {
        console.log('errored', response)
      } else {
        console.log('passed', response)
        updatedItem = {...item, ...response, timeOut: new Date(date.getTime() + (successBannerTime * 1000))}
        setSuccessItem(updatedItem)
        queryClient.invalidateQueries(["auctionDetail"])
      }
    }
    catch(e) {
      setBidResponse(e)
    }
    //reset bid response for downstream checks on bid response
    showLoading(false)
  }

  //use effect if bidSubscriptionResponse changes
  useEffect(() => {

    //if item bid on is in auction, update the auction details
    if (auctionDetail && bidSubscriptionResponse?.ItemId) {

      const isInAuction = (item) => item.ItemId === bidSubscriptionResponse.ItemId
      //if bid response item id is in auction items array
      if (auctionDetail.Items.findIndex(isInAuction) > -1) {
        //invalid query to repull
        queryClient.invalidateQueries(["auctionDetail"])
      }
    }

  }, [bidSubscriptionResponse, auctionDetail, queryClient])

  const registerForAuction = async (auction) => {
    showLoading(true)
    const signUpResponse = await registerUserForAuction(auction.AuctionCode, auctionUser)
    showLoading(false)
  }

  // maybe provide a link for if no auction is loaded to reroute to load auctions - 404 something
  if (!auctionDetail) return (<></>)

  //for iframe to update the state variable, to push a refresh of status
  window.addEventListener('message', (event) => {
    // if (event.origin === 'https://your-app-domain.com') { // Check the origin
      const { action } = event.data;
      setIframeAction(action)
    // }
  })

  // if (auctionNeedsTime) 

  return (
    <div className={css['auction-container']}>
      <AuctionStatus auction={auctionDetail} updateStatus={updateStatus} forced={forced} />

      {showBidModal && <ConfirmBidModal submitFn={submitBid} item={bidItem} bidAmount={bidAmount}
        showBidModal={showBidModal} setShowBidModal={setShowBidModal} bidResponse={bidResponse} setBidResponse={setBidResponse} />}

        <BidSuccessNotification successItem={successItem} />

      <h2 id="landingPageTitle">
        {auctionDetail.Title}
      </h2>
      
      <div className={themeCss['blue-background']}>
        
        {userAttending && <div>
          <AuctionCountdown auctionDetail={auctionDetail} auctionState={auctionState} isCheckedIn={isCheckedIn} />
        </div>}

        <p className={css['auction-description-unreg']}>Please check back soon, auction administrators are still adding items to the auction!</p>

        {!userAttending && <>
            <button className={`${themeCss['FilledYellow']} ${themeCss['button']}`}
                onClick={() => registerForAuction(auctionDetail)}
                disabled={auctionState === 'ended'}
              >Sign Up For Auction</button>
          </>}
        
        {!textAlertNumber && userAttending && <TextMessageOff textAlertNumber={textAlertNumber} auctionUser={auctionUser} auction={auctionDetail} />}
        {textAlertNumber === 'declined' && userAttending && <TextMessageOff textAlertNumber={textAlertNumber} auctionUser={auctionUser} auction={auctionDetail} />}
        {textAlertNumber && userAttending && textAlertNumber !== 'declined' && <TextMessageOn auctionUser={auctionUser} auction={auctionDetail} />}

        <CustomMarkdown text={auctionDetail.Description} cssClass={css['auction-description-unreg']} />

        {/* {userAttending && <UnregisterButton auctionDetail={auctionDetail} auctionUser={auctionUser} />} */}
      </div>
    </div>
  )
}