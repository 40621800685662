import { useEffect, useState } from 'react'

import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { history } from 'services/history.js'

import { getImageUrl } from '../util/images'
import { getAuctionStatus } from '../util/dateString'

import css from './sass/adminauction.module.scss'
import themeCss from '../components/sass/theme.module.scss'

import AuctionStatusAlert from './AuctionStatusAlert'
import PreviewPublishChecklist from './PreviewPublishChecklist'

export default function AuctionCard(props) {

  const auction = props.auction

  const [bannerUrl, setBannerUrl] = useState()
  
  const currentTime = new Date()  
  const liveStatus = getAuctionStatus(currentTime, auction?.StartTime, auction?.EndTime)

  //set auction kind to silent for now - paddle to come later
  let kind = 'silent'

  // use effect for auction banner image
  useEffect(() => {
    const url = getImageUrl(auction?.AuctionCode, 'banner', auction?.BannerImageName)
    setBannerUrl(url)
  }, [auction])

  let auctionKindElement = <></>
  if (kind === 'silent') auctionKindElement = <p className={css['auction-status-kind']}>SILENT AUCTION</p>
  if (kind === 'paddle') auctionKindElement = <p className={css['auction-status-kind']}>PADDLE AUCTION</p>


  return (
    <div className={css['auction-card-container']}>
      <img className={css['auction-card-grid-image']} src={`${bannerUrl}`} alt={`${auction.Title} banner`} />
      <div className={css['auction-card-grid-status']}>
        {auctionKindElement}
        <AuctionStatusAlert liveStatus={liveStatus} auctionStatus={auction.Status} />
      </div>
      <a className={css['auction-card-grid-title']}
          onClick ={(e) => {history.push({
            pathname: '/auctionhome',
            search: `?auctionId=${auction.AuctionCode}`,
            // auctionDetail: auction
          })}}>{auction?.Title}
        </a>

      <div className={css['auction-card-info']}>
        <p>Start Time</p>
        <p>{new Date(auction.StartTime).toLocaleString()}</p>
      </div>

      <div className={css['auction-card-info']}>
        <p>End Time</p>
        <p>{new Date(auction.EndTime).toLocaleString()}</p>
      </div>

      {/* <div className={css['auction-card-info']}>
        <p>Invited / RS</p>
        <p>42/50</p>
      </div> */}

      <div className={css['auction-card-info']}>
        <p># of Items</p>
        <p>{auction.Items?.length}</p>
      </div>

      {/* <div className={css['auction-card-grid-buttons']}> */}
      <div className={css['auction-card-info']}>
        <Link className={`${themeCss['button']} ${themeCss['FilledBlue']}`}
          to={`/admin/auctiondetail?auctionId=${auction.AuctionCode}`}>
          Manage Auction
        </Link>
      
      </div>
      <div className={css['auction-card-grid-checklist']}>
        <PreviewPublishChecklist liveStatus={liveStatus} auctionDetail={auction} location={'card'} />
      </div>
    </div>
  )
}