import styled from 'styled-components';

const ContactStyles=styled.div`
  color:#000000;
  .ucla-address{
    display: inline-block;
    text-align:left;
    margin:0 50px;
    p{
      margin:0;
    }
  }
`

const Faq1 = () => (
  <ContactStyles>
    <p>Please call (310) 794-2447 or email <a href="mailto:mygivinggateway@support.ucla.edu">MyGivingGateway@support.ucla.edu</a>.
    The office is open Monday through Friday from 10:00 a.m. to 3:00 p.m. PT. Our mailing address is below. </p>
    <div className="ucla-address">
      <p>UCLA Donor Relations</p>
      <p>10889 Wilshire Boulevard, Suite 1500</p>
      <p>Los Angeles, CA 90024</p>
    </div>
  </ContactStyles>

)

export default Faq1
