import { useState, useEffect } from 'react'

import infoIcon from '../components/images/info-icon-orange.svg'
import { formatMoney } from 'services/util'

import themeCss from './sass/theme.module.scss'
import css from './sass/bidmodal.module.scss'

export default function ConfirmBidModal( { item, bidAmount, showBidModal, setShowBidModal, submitFn, bidResponse, setBidResponse, auctionDetail, initialBid, setInitialBid } ) {  

  const [loadedBid, setLoadedBid] = useState(bidAmount)
  const [failedBid, setFailedBid] = useState(false)
  const [updatedBid, setUpdatedBid] = useState()

  console.log('34534534534534534534534', bidResponse)

  useEffect(() => {
    //if there is an error from bid response, set failed bid to true to show the front end notify updates
    // update the loaded bid to the new bid amount 
    if (bidResponse?.error) {
      setFailedBid(true)
      setBidResponse(undefined)
    }
    //if modal is showing
    if (showBidModal) {

      //check to see if the bid amount was successful
      if (bidResponse?.ItemId === item.ItemId) {
        setShowBidModal(false)
        //reset bid response to undefined to return to original state
        setBidResponse(undefined)
      }
    }

  }, [bidResponse])


  //use effect for when the item price changes
  useEffect(() => {
    // set a failed bid only when the current bid price is less than the item + the min raise

    console.log('hello something changed')
    const updatedItem = auctionDetail?.Items.find((check) => check.ItemId === item.ItemId)
    const newMinimumBid = updatedItem.CurrentBid + updatedItem.MinRaise

    // console.log('new min bid', newMinimumBid)
    // console.log('loaded bid', loadedBid)
    // console.log('initialBid', initialBid)

    if (initialBid === null || initialBid === undefined) {
      if (loadedBid < newMinimumBid) {
        setUpdatedBid(newMinimumBid)
        setFailedBid(true)
        console.log('c')
      }
      else {
        setUpdatedBid(loadedBid)
        setFailedBid(false)
        console.log('d')
      }
    }

    if (initialBid > 0) {
      if (initialBid < loadedBid) {
        setUpdatedBid(newMinimumBid)
        setFailedBid(true)
        console.log('a')
      } else {
        setUpdatedBid(loadedBid)
        setFailedBid(false)
        console.log('b')
      }
    }

  }, [auctionDetail, initialBid])

  useEffect(() => {
    if (loadedBid !== bidAmount) setFailedBid(true)
  }, [loadedBid, bidAmount])

  useEffect(() => {
    if (failedBid) {
      setBidResponse(undefined)
      setLoadedBid(bidAmount)
    }
  }, [failedBid])

  return (
    <div className={css['bid-modal-container']}>
      <div className={themeCss['modal-background']} onClick={() => { setShowBidModal(false) }}></div>

      <div className={`${css['bid-modal-content']} ${themeCss['box-top-yellow-accent-wide']}`}>
        {failedBid ? 
          <div className={css['bid-modal-failed-header']}>
            <img className={css['bid-modal-info-icon']} src={infoIcon} alt='Information' />
            <p className={css['bid-modal-content-header']}>Highest bid has changed</p>
          </div> :
          <p className={css['bid-modal-content-header']}>Confirm Bid</p>
        }
        <p className={css['bid-modal-content-title']}>{item?.Title}</p>

        {failedBid ? 
          <p className={css['bid-modal-content-bid']}>The current bid is now ${formatMoney(updatedBid)}</p> :
          <p className={css['bid-modal-content-bid']}>${formatMoney(bidAmount)}</p>
        }
        
        {failedBid ? 
          // <p className={css['bid-modal-content-text']}>Do you want to place a bid for this new amount?</p> :
          <p className={css['bid-modal-content-text']}>Please cancel bid and try again with a new amount.</p> :
          <p className={css['bid-modal-content-text']}>Are you sure you want to place a bid for this amount?</p>
        }
        
        <div className={css['bid-modal-actions']} >
            <button disabled={failedBid} className={`${themeCss['FilledBlue']} ${themeCss['button']}`} onClick={()=>submitFn(item, bidAmount)}>
              Confirm Bid
            </button>

          <div onClick={() => { setShowBidModal(false) }}>
            <p className={css['bid-modal-cancel']}>
              Cancel Bid
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}