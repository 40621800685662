import { Link } from 'react-router-dom';

import EmailPhoneContact from './EmailPhoneContact'

import styled from 'styled-components';


export const NewgiftStyles = styled.div`
ul{
  padding: 0 ${10/16}rem;
}
li{
  list-style-type: disc;
  margin:10px;
  p:nth-child(1),p:last-child{
    margin:0
  }


}
.ucla-address{

  text-align:left;
  margin:0 50px;
  p{
    margin:0;
  }
}

`

const Faq5 = () => (
  <NewgiftStyles>
    <p>If you would like to donate to an area you have supported in the past, please click the “Give Again” button next to your previous gift and you will have the option to donate again.</p>
    <p>To make a gift to a new area, please see below or call (310) 794-2447 for assistance.</p>
    <ul>
      <li> Online
        <p><a href="https://www.giveto.ucla.edu" rel="noreferrer" target="_blank">www.giveto.ucla.edu</a></p>
      </li>

      <li> Check
        <p>All checks should be made payable to <strong>The UCLA Foundation</strong> and mailed to:</p>
        <div className="ucla-address">
          <p>The UCLA Foundation</p>
          <p>10889 Wilshire Boulevard, Suite 1000</p>
          <p>Los Angeles, CA 90024</p>
        </div>

        <p>Please include the name of the fund you would like to support and the associated fund number on the memo line of your check.</p>
      </li>

      <li> Stocks and Securities
        <p>Gifts of appreciated securities are tax deductible at their full market value. In most cases, appreciation in the value of the security benefits the university
           and does not have tax consequences for the donor. Please contact (310) 794-3333 for more information.</p>
      </li>

      <li> Matching Gifts
        <p>The easiest way to double—or even triple—your contribution to UCLA is to request a match from your employer. Thousands of companies match gifts to the organizations and institutions their employees support.
          To see if your employer is one of them, please <a target="_blank" rel="noreferrer" href="http://www.matchinggifts.com/ucla/">click here</a>.</p>
      </li>

      <li> Bequests
        <p>If you would like all or a portion of your estate to benefit UCLA, please specify in your will or living trust that the funds be directed to The UCLA Foundation.
           For information on including UCLA in your estate plans, please call (800) 737-8252 or <a target="_blank" rel="noreferrer" href="http://www.legacy.ucla.edu/">click here</a>.</p>
      </li>

      <li> Retirement plans
        <p>For information on naming UCLA as a beneficiary of your retirement plan, please call (800) 737-8252.</p>
      </li>

    </ul>

    <div>
      <div>
        <p><strong> Questions About Your Gift</strong></p>
        <p>If you see any discrepancies or have concerns about a gift listed in your giving history, <EmailPhoneContact/>.</p>
      </div>

      <div>
      <p> <strong> No Goods or Services</strong></p>
        Each individual tax receipt states whether or not goods and services were received in exchange for a gift. You can print receipts <Link to="/history">here</Link>.
        If you require a separate letter stating that no goods or services were provided in exchange for your gift, <EmailPhoneContact/>
      </div>

      <div>
      <p><strong>Payment Information and Recurring Gifts</strong></p>
      To provide updated debit or credit card information, or to make changes to a pledge or recurring gift, please <a target="_blank" rel="noreferrer" href="https://myaccount.support.ucla.edu/login/portal.aspx?st=g&action=pp">log in to PledgePay</a>.
        If you would rather speak with someone directly, please call (310) 794-2447.
      </div>

      <div>
      <p><strong>Updating Your Personal Information</strong></p>
        To update your biographical information, please <Link to="/profile">click here</Link>.
      </div>

      <div>
      <p><strong>Receipts</strong></p>
        To generate a gift receipt, please visit the <Link to="/history">My Giving</Link> page. You can print individual receipts or consolidated receipts by year.
        If you need assistance, <EmailPhoneContact/>
      </div>

      <div>
      <p><strong>Refunds</strong></p>
        To request a refund, <EmailPhoneContact/>
      </div>



    </div>

  </NewgiftStyles>

)
export default Faq5
