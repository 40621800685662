import { useEffect, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { graphql } from '../../api'
import { updateAuctionItemMutation } from '../../graphql/Mutations'
import { adminGetAuctionByCode } from '../auctionsadminutil'

import css from './sass/closedauction.module.scss'
import themeCss from '../../components/sass/theme.module.scss'
import ClosedManageCheckoutRow from './ClosedManageCheckoutRow'


export default function ClosedManageCheckout(props) {
  const [itemsPaidCount, setItemsPaidCount] = useState(0)
  const [itemsUnpaidCount, setItemsUnpaidCount] = useState(0)

  const [selectedItems, setSelectedItems] = useState([])

  //array of truth for all attendees in auction
  const [attendeeItems, setAttendeeItems] = useState([])

  //to refresh query on changes
  const queryClient = useQueryClient()

  const {auctionCode, attendeeId: userId, toAllAttendeePage} = props

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["adminAuctionDetail", auctionCode],
    queryFn: async () => {
      return await adminGetAuctionByCode(auctionCode)
    },
    staleTime: 0
  })

  const {data: updateItemResponse, updateItemError, isPending, mutate: updateItem } = useMutation({
    mutationFn: (input) => {
      return graphql({query: updateAuctionItemMutation, variables: {input: input} })
    },
  })

  //use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }

  }, [auctionDetail, error, isLoading])

  const markAsPaid = async (e) => {
    const input = {
      ItemId: selectedItems[0],
      Paid: true
    }
    updateItem(input)
  }


  useEffect(() => {
    showLoading(isPending)

    if (updateItemError) {
      console.log("Item not updated: ", updateItemError)
      console.log(updateItemError.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (updateItemResponse) {
      console.log(updateItemResponse)
      console.log('Item updated: ', updateItemResponse.data.updateAuctionItem.ItemId)
      queryClient.invalidateQueries(['adminAuctionDetail'])
    }
  }, [updateItemResponse, updateItemError, isPending, queryClient])

  // use effect to take adminAuctionDetail and reformat the data for attendee display, assigning it to attendeeItems for mapping in JSX 
  // maybe could reformat into adminAuctionDetail if we don't need all of the details the query returns
  useEffect(() => {

    console.log(auctionDetail)
    if (auctionDetail) {
      const adminItems = auctionDetail?.Items
      
      const userItems = adminItems.filter((item) => item.CurrentHighBidder === userId)

      const itemsPaid = userItems.reduce((acc, item) => {
        if (item.Paid) return acc + 1
        else return acc
      }, 0)

      setItemsPaidCount(itemsPaid)
      setItemsUnpaidCount(userItems.length - itemsPaid)
      setAttendeeItems(userItems)

    }

  }, [auctionDetail, userId])

  const toggleCheckbox = (userId) => {
    const userIndex = selectedItems.indexOf(userId)
    const returnArr = [...selectedItems]

    if (userIndex > -1) returnArr.splice(userIndex, 1)
    else returnArr.push(userId)

    setSelectedItems(returnArr)


  }

  const selectAllItems = (checked) => {
    let returnArr = [];

    if (checked) {
      returnArr = attendeeItems.map((attendee) => attendee.ItemId)
    } else returnArr = []

    console.log('return Arr', returnArr)

    setSelectedItems(returnArr)

  }

  const countItems = () => {
    return selectedItems.reduce((count, value) => count + (value ? 1 : 0), 0)
  }

  if (!auctionDetail || !attendeeItems) return (<></>)

  return (
    <div className={css['closed-auction-container']}>

      <div className={css['closed-auction-attendee-heading']}>
        <h2>ATTENDEE NAME: {attendeeItems[0]?.CurrentHighBidderInfo?.FirstName} {attendeeItems[0]?.CurrentHighBidderInfo?.LastName}</h2>
        <button className={`${themeCss['button']} ${themeCss['FilledBlue']}`} onClick={() => toAllAttendeePage()}>
          Back to All Attendees
        </button>
      </div>

      <div className={css['closed-auction-summary']}>
        <div>
          <p>{itemsUnpaidCount} ITEMS WITH BALANCE DUE</p>
          <p>{itemsPaidCount} ITEMS WITH FULLY PAID BALANCE</p>
        </div>
      </div>

      <div className={css['closed-auction-charge-selected']}>
        <p>
          {countItems()} ITEMS SELECTED
        </p>
        <button disabled={countItems() > 1} 
          className={`${themeCss['button']} ${themeCss['FilledBlue']}`}>
          Charge Amount Due
        </button>
        {/* <button disabled={countItems() > 1} className={`${themeCss['button']} ${themeCss['FilledWhite']}`}>
          Allow Alt. Payment
        </button> */}
        <button disabled={countItems() > 1} onClick={() => markAsPaid()}
          className={`${themeCss['button']} ${themeCss['FilledWhite']}`}>
          Mark As Paid
        </button>
        <p className={css['closed-auction-charge-note']}>
          Currently charging the constituent for an item must be done one item at a time.
        </p>
      </div>


      <div className={css['closed-auction-table']}>
        <table>
          <thead>
            <tr className={css['closed-auction-table-header']}>
              <th>
                <input type='checkbox' onChange={(e) => selectAllItems(e.target.checked)}
                  checked={selectedItems.length === attendeeItems.length}
                />
              </th>
              <th>Item Name</th>
              <th>Item Amount Due</th>
              {/* <th>Item Amount Paid</th> */}
              {/* <th>Alt. Payment Allowed?</th> */}
              <th>Paid</th>
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {attendeeItems && attendeeItems.map((item) => {
            return <ClosedManageCheckoutRow key={item.ItemId} 
                    selectedItems={selectedItems} toggleCheckbox={toggleCheckbox}
                    item={item} />
            })}
          </tbody>
        </table>
      </div>
      
    </div>
  )
}