const oneHour = 1000 * 60 * 60
const oneDay = oneHour * 24

export const timeUntil = (currentTime, dateString) => {
  const timeInZone = new Date(currentTime)
  const auctionDate = new Date(dateString)  
  const timeDiff = auctionDate - timeInZone

  if (timeDiff < 0) return null
  else if (timeDiff <= oneHour) return 'withinHour'
  else if (timeDiff <= oneDay) return 'withinDay'

  return null
}

export const toEndingString = (currentTime) => {

  currentTime.setHours(currentTime.getHours() - 8);

  let fullTimeStr = currentTime.toISOString()
  return fullTimeStr.slice(0,16)

}

export const getAuctionStatus = (currentTime, startTime, endTime) => {
  const timeInZone = new Date(currentTime) 
  const endTimeDate = new Date(endTime) - 0
  const startTimeDate = new Date(startTime) - 0

  if (endTimeDate <= timeInZone) return 'ended'
  if (startTimeDate <= timeInZone) return 'started'
  else return 'before'
}

export const hourTimer = (currentTime, timerOver) => {
  const timeInZone = new Date(currentTime)  
  const endTimeDate = new Date(timerOver) - 0

  // console.log('time in zone ', timeInZone)
  // console.log('end time in ', new Date(timerOver))

  let timeDiff = endTimeDate - timeInZone

  //remainders after full hours are taken out
  const hourRemainder = timeDiff % (1000 * 60 * 60)

  // number of full hours left
  let numHours = (timeDiff - hourRemainder) / (1000 * 60 * 60)
  // if there are less than ten hours
  if (numHours < 10) numHours = `0${numHours}`
  
  //day logic

  //if there are more than 24 hours left
  if (numHours > 24) {
    //find the leftover hours after full days are taken out
    const dayRemainder = numHours % 24
    //find the total number of full days left
    const numDays = (numHours - dayRemainder) / 24

    //if there are less than ten hours left in the remainder
    if (dayRemainder < 10) {
      numHours = `${numDays} day${numDays>1?'s':''} 0${dayRemainder}`
    } else {
      numHours = `${numDays} day${numDays>1?'s':''} ${numHours - numDays*24}`
    }
  }

  const minRemainder = hourRemainder % (60 * 1000)
  let numMinutes = (hourRemainder - minRemainder) / (60*1000)
  if (numMinutes < 10) numMinutes = `0${numMinutes}`

  const secRemainder = minRemainder % 1000
  let numSeconds = (minRemainder - secRemainder) / 1000
  if (numSeconds < 10) numSeconds = `0${numSeconds}`

  let countdownString = `${numHours}:${numMinutes}:${numSeconds}`
  return countdownString.indexOf('NaN') > -1 ? '' : countdownString
}