import { useEffect, useState} from 'react'
import { useQuery } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { subscribe, getLiveAuctionData, getAuctionByCode } from '../../api'
import { urlQueryParser } from '../../util/urlQueryParser'
import { onUpdateCurrentBidSubscription } from '../../graphql/Subscriptions'

import css from './sass/liveauction.module.scss'

import LiveItems from './LiveItems'
import LiveUserBids from './LiveUserBids'

export default function LiveAuctionView(props) {
  
  const [auctionDetail, setAuctionDetail] = useState()
  const [auctionId, setAuctionId] = useState()
  const [users, setUsers] = useState()
  const [totals, setTotals] = useState({bidCount: 0, totalAmountBid: 0})
  const [userFullNamesMap, setUserFullNamesMap] = useState({})
  const [loadError, setLoadError] = useState()
  // const [auctionCode, setAuctionCode] = useState(urlQueryParser(props.location.search).auctionId)
  const [auctionCode, setAuctionCode] = useState(props.auctionCode)

  // console.log(urlQueryParser(props.location.search).auctionId)
  // const { auctionId: auctionCode } = urlQueryParser(props.location.search)

  // get first load of auction data by code, so it will work for deep-linking
  const { data, error, isLoading } = useQuery({
    queryKey: ["liveAuctionDetail", auctionCode],
    queryFn: async () => await getAuctionByCode(auctionCode),
    staleTime: 0
  })

  useEffect(() => {
    showLoading(isLoading)

    if (error || (data && data.error)) {
      console.log('Error!', error || data.error)
      setLoadError(data.error)
    }
    else if (data) {
      console.log('use effect data setting into the new auction')
      setAuctionDetail(data)
      setAuctionId(data.AuctionId)
    }
  }, [data, error, isLoading])

  useEffect(() => {
    if (!auctionId) return

    const getLatestAuctionDetailForAllItems = async () => {
      setAuctionDetail(await getLiveAuctionData(auctionId))
    }

    const subCreateFiltered = subscribe({query: onUpdateCurrentBidSubscription, variables: {auctionId: auctionId}}, {
      next: (result) => {
        console.log('subscription result', result)
        getLatestAuctionDetailForAllItems()
      },
      error: (error) => {
        console.error("Subscription error!: ", error.error.errors[0].message)
      }
    })

    return () => {
      console.log('unsubscribing...')
      subCreateFiltered.unsubscribe()
    }
  }, [auctionId])

  useEffect(() => {
    if (auctionDetail && auctionDetail.Bids) {

      let bidCount = 0, totalAmountBid = 0
      auctionDetail.Items.forEach(item => {
        bidCount += item.BidCount
        totalAmountBid += item.CurrentBid
      })
      setTotals({bidCount, totalAmountBid})

      const currentlyWinningBids = {}
      const sortedUsers = auctionDetail.Bids.reverse().reduce((result, currentBid) => {
        const existingUserEntry = result.find((entry) => entry.UserId === currentBid.UserId)

        // only record winning bid amounts in total, high bid should always come first (may fail in weird race conditions, don't rely on)
        let totalBidAmount = existingUserEntry?.totalBidAmount || 0
        if (!currentlyWinningBids[currentBid.ItemId] || currentlyWinningBids[currentBid.ItemId] < currentBid.BidAmount) {
          totalBidAmount += currentBid.BidAmount
          currentlyWinningBids[currentBid.ItemId] = currentBid.BidAmount
        }

        if (existingUserEntry) {
          existingUserEntry.numberOfBids++
          existingUserEntry.FullName = currentBid.FullName
          existingUserEntry.totalBidAmount = totalBidAmount
        }
        else {
          result.push({
            UserId: currentBid.UserId,
            FullName: currentBid.FullName,
            numberOfBids: 1,
            totalBidAmount: totalBidAmount,
          })
        }

        return result
      }, []).sort()

      setUsers(sortedUsers)
    } else if (auctionDetail && auctionDetail.Bids === null) {
      setUsers([])
    }
  }, [auctionDetail])

  useEffect(() => {
    if (users) {
      const fullNames = {}
      for (const user of users) {
        fullNames[user.UserId] = user.FullName
      }
      setUserFullNamesMap(fullNames)
    }
  }, [users])

  if (loadError)
    return <p>Error: {JSON.stringify(loadError,0,2)}</p>

  if (!auctionCode)
    return (<h2>Auction Code Missing in URL</h2>)

  if (!auctionDetail)
    return (<></>)

  console.log('users', users)

  return (
    <div className={css['admin-container']}>

      <p><b>Total Number of Bids:</b> {totals.bidCount}</p>
      <p><b>Total Amount Bid:</b> {totals.totalAmountBid}</p>

      <h3>Items</h3>
      <table className={css['admin-items-table']}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Bid Count</th>
            <th>Current Bid</th>
            <th>Current High Bidder</th>
            <th>Fair Market Value</th>
            <th>Starting Bid</th>
            <th>Minimum Raise</th>
          </tr>
        </thead>
        <tbody>
        {auctionDetail.Items && auctionDetail?.Items
                              .sort((a,b) => a.Title.toLowerCase() < b.Title.toLowerCase() ? -1 : 1)
                              .map((item, idx) => {
          return (
            <tr key={item.ItemId}>
              <td>{item.Title} {item.BidCount > 0 && (<LiveItems auctionDetail={auctionDetail} item={item} />)}</td>
              <td>{item.BidCount}</td>
              <td>{item.CurrentBid}</td>
              <td>{userFullNamesMap[item.CurrentHighBidder]}</td>
              <td>{item.Value}</td>
              <td>{item.StartingBid}</td>
              <td>{item.MinRaise}</td>
            </tr>)
        })}
        </tbody>
      </table>

      <h3>Bidders</h3>
      <table className={css['admin-users-table']}>
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Bid Count</th>
            <th>Total Currently Winning</th>
          </tr>
        </thead>
        <tbody>
          {users && users?.map((user, idx) => {
            return (
              <tr key={user.UserId}>
                <td>{user.FullName} {<LiveUserBids auctionDetail={auctionDetail} user={user.UserId} />} </td>
                <td>{user.numberOfBids}</td>
                <td>{user.totalBidAmount}</td>
              </tr>)
          })}
        </tbody>
      </table>
    </div>
  )
}