import EnterPayment from './EnterPayment'

export default function PaymentUpdate() {

  const header = 'Update Payment Method'
  const message = ''
  const buttonText = 'Update Payment Card'

  return (
    <>
      <EnterPayment header={header} message={message} buttonText={buttonText} />
    </>
  )
}