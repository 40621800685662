import { useEffect, useState, useMemo, useRef } from "react"
import { getAuctionStatus, hourTimer } from "../util/dateString"
import { Link } from 'react-router-dom'

import css from './sass/markdown.module.scss'

// replaces:
// \n with <p></p>
// [link text](https://site.com?foo=bar) with <a target="_blank" rel="noreferrer" href="https://site.com?foo=bar">link text</a>

export default function CustomMarkdown({text, cssClass, noLineBreaks, linkObj}) {

  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkIfTruncated = () => {
      if (textRef.current) {
        const currentText = textRef.current;
        const scrollHeight = currentText.scrollHeight; // full content height
        const clientHeight = currentText.clientHeight; // display height

        // set value for if the content height exceeds the visible height
        setIsTruncated(scrollHeight > clientHeight);
      }
    };

    checkIfTruncated();
    window.addEventListener('resize', checkIfTruncated); // Check on resize

    return () => {
      window.removeEventListener('resize', checkIfTruncated); // Cleanup
    };
  }, []);


  const doAllReplaces = (text) => {
    const step1 = doLinkReplace(text) // this one takes a stirng, all the other parse methods take an array of text strings
    const step2 = doItalicsReplace(step1) // make sure the most atomic thing goes last (IE that can't have another element we're parsing inside of it)

    return step2
  }

  const doLinkReplace = text => {
    const preParsedText = text
                        .replaceAll('|', '&&&pipe&&&')
                        .replaceAll('~', '&&&squiggly&&&')
                        .replace(/\[([^[]+)\](\(([^)]*))\)/gim, '|$3~$1|')
                        .split('|')

    const reAddPipesAndSqugglies = oldText => {
      return oldText.replaceAll('&&&pipe&&&', '|').replaceAll('&&&squiggly&&&', '~')
    }

    const finalParsedText = preParsedText.map(parsedSection => {
      if (parsedSection.indexOf('~') > -1) {
        const [ href, linkText ] = parsedSection.split('~')
        return (<a target="_blank" rel="noreferrer" href={reAddPipesAndSqugglies(href)}>{reAddPipesAndSqugglies(linkText)}</a>)
      }
      else {
        return reAddPipesAndSqugglies(parsedSection)
      }
    })

    return finalParsedText
  }

  const doItalicsReplace = textArray => {
    const finalParsedArray = []

    textArray.forEach(section => {
      // already a react element
      if (section.props) {
        finalParsedArray.push(section)
      }
      else {
        console.log({section})
        const italicsArray = section.split('<i>')

        italicsArray.forEach(parsedSection => {
          const [toMaybeItalicize, remainder] = parsedSection.split('</i>')

          if (remainder) {
            finalParsedArray.push(<i>{toMaybeItalicize}</i>)
            finalParsedArray.push(remainder)
          }
          else {
            finalParsedArray.push(toMaybeItalicize)
          }
        })
      }
    })

    return finalParsedArray
  }

  const linkElement = <Link className={css['link-toitem']} to={{ pathname: '/auction/item', search: `?auctionId=${linkObj?.auctionCode}&itemId=${linkObj?.itemCode}`}}>
      (more)
    </Link>

  return (
    <>
      {noLineBreaks &&
        <p ref={textRef} className={cssClass || 'none'}>{doAllReplaces(text).map(section => <>{section}</>)}</p>
      }
      {!noLineBreaks &&
        text.split('\n').map(paragraph =>
          <p ref={textRef} className={cssClass || 'none'}>{doAllReplaces(paragraph).map(section => <>{section}</>)}</p>
        )
      }
      {isTruncated && linkObj.link === 'itemPage' ? linkElement : ''}
    </>
  )
}
