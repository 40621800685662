import { useEffect, useState } from 'react'
import { useQuery } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import adminCss from './sass/adminpage.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import AdminItemPage from './AdminItemPage'
import ItemDisplay from './ItemDisplay'


export default function AdminAuctionItems(props) {

  return (
    <div className={adminCss['admin-auction-page-tab-container']}>
      <h3 className={adminCss['admin-auction-page-tab-header']}>
        Auction Items
      </h3>

      <ItemDisplay selectedAuction={props.auction} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />
      
      
    </div>
  )
}