import EnterPayment from './EnterPayment'

export default function PaymentAdd() {

   const header = 'Add a Payment Method'
   const message = 'Please add a payment method to participate in this auction.'
   const buttonText = 'Add Payment Card'

  return (
    <>
      <EnterPayment header={header} message={message} buttonText={buttonText} />
    </>
  )
}