import { Link } from 'react-router-dom'

import css from './sass/closedauction.module.scss'

export default function ClosedBiddingRow( {attendee, auctionCode, selectedAttendees, toggleCheckbox, toAttendeePage} ) {
  console.log(attendee)

  const formatName = (attendee) => {
    if (!attendee.CurrentHighBidderInfo) return 'Error loading'
    return `${attendee.CurrentHighBidderInfo.FirstName} ${attendee.CurrentHighBidderInfo.LastName}`
  }


  return (
    <tr className={css['closed-auction-table-data-row']}>
      <td>
        <input type='checkbox'
          checked={selectedAttendees.indexOf(attendee.CurrentHighBidder) > -1}
          onChange={() => toggleCheckbox(attendee.CurrentHighBidder)} 
          />
      </td>
      <td>{formatName(attendee)}</td>
      <td>{attendee.TotalDue}</td>
      <td>{attendee.TotalPaid}</td>
      <td>{attendee.Items.length}</td>
      {/* <td>{'alt'}</td> */}
      <td>
        <a className={css['action-link']} onClick={() => toAttendeePage(attendee.CurrentHighBidder)}>
            Manage Checkout
        </a>
      </td>
    </tr>
  )
}